import type { RouteLocationRaw } from 'vue-router';

import { useUserStore } from '@/stores/user.store';

export default async function guestGuard(): Promise<Partial<RouteLocationRaw> | void> {
  const store = useUserStore();

  await store.fetchUser();

  const { user: storedUser } = store;

  if (storedUser) {
    return { name: 'home' };
  }
}
