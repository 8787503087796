<template>
  <div class="flex min-h-screen flex-col md:flex-row">
    <div class="grow">
      <TheFullPageHeader class="sm:p-10" />
      <main class="mx-auto max-w-md p-5 lg:p-0">
        <NavigationBackButton v-if="route.meta.hasNavigationBack" />
        <slot />
      </main>
      <footer class="text-center lg:mx-auto lg:max-w-md lg:text-left">
        <RouterView name="footer"></RouterView>
      </footer>
    </div>
    <RouterView class="w-full md:h-screen md:w-96" name="rightSidebar"></RouterView>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';

import NavigationBackButton from '@/components/NavigationBackButton.vue';
import TheFullPageHeader from '@/components/TheFullPageHeader.vue';

const route = useRoute();
</script>
