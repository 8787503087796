<template>
  <p :id="helperTextId" class="text-sm font-light text-red-700">
    {{ errorMessage }}
  </p>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';

const props = defineProps<{
  inputId: string;
  helperTextId?: string;
}>();

const { errorMessage } = useField(props.inputId);
</script>

<style scoped></style>
