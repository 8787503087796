<template>
  <KeepAlive>
    <component :is="icon" :class="type" />
  </KeepAlive>
</template>
<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

const props = defineProps<{
  name: string;
  type?: 'error' | 'default';
}>();

const icon = defineAsyncComponent(() => import(`../assets/svg/${props.name}.svg`));
</script>

<style scoped>
.default {
  color: theme('colors.brick.800');
}
.error {
  color: theme('colors.red.500');
}
</style>
