import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { useUserStore } from '@/stores/user.store';

export default async function authGuard(
  to: RouteLocationNormalized,
): Promise<Partial<RouteLocationRaw> | void> {
  const existingQueries = to.query;
  const loginQuery = { path: '/login', query: { redirect: to.path, ...existingQueries } };
  const store = useUserStore();

  if (store.user) {
    return;
  }

  await store.fetchUser();

  if (!store.user) {
    return loginQuery;
  }
}
