<template>
  <div>
    <template v-if="user">
      <ProfileConnectionForm
        v-if="showConnectionStatus.notTriedOrExpired"
        :user-id="user.id"
        :is-link-expired="isLinkExpired"
      />
      <ProfileNotConnected v-else-if="showConnectionStatus.noConnection" />
      <ProfileConnectionPending v-else-if="showConnectionStatus.pending" />
      <template v-else-if="showConnectionStatus.connected">
        <ProfileConnected v-if="shouldShowMedicalBox" />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import ProfileConnected from '@/components/ProfileConnected.vue';
import ProfileConnectionForm from '@/components/ProfileConnectionForm.vue';
import ProfileConnectionPending from '@/components/ProfileConnectionPending.vue';
import ProfileNotConnected from '@/components/ProfileNotConnected.vue';
import { useUserPreferencesStore } from '@/stores/user-preferences.store';
import { useUserStore } from '@/stores/user.store';
import { MedicalConnectionStatus } from '@/types/api/MedicalConnectionStatus.enum';
import { PatientFinderResult } from '@/types/api/PatientFinderResult.enum';
import { isOlderConnection } from '@/types/api/User.model';

const userStore = useUserStore();
const userPreferencesStore = useUserPreferencesStore();
userPreferencesStore.fetchPreferences();
const { user } = storeToRefs(userStore);
const { shouldShowMedicalBox } = storeToRefs(userPreferencesStore);

const isLinkExpired = computed((): boolean => {
  return (
    user.value?.medicalConnectionStatus === MedicalConnectionStatus.NO_CONNECTION &&
    user.value?.lastMedicalConnectionResult === PatientFinderResult.FULL_MATCH
  );
});

const showConnectionStatus = computed(() => {
  return {
    notTriedOrExpired:
      user.value?.medicalConnectionStatus === MedicalConnectionStatus.NOT_YET_TRIED ||
      isLinkExpired.value,
    noConnection: user.value?.medicalConnectionStatus === MedicalConnectionStatus.NO_CONNECTION,
    pending: user.value?.medicalConnectionStatus === MedicalConnectionStatus.PENDING,
    connected: showConnectedBox.value,
  };
});

const showConnectedBox = computed(() => {
  const currentUser = user.value;

  if (currentUser === null) {
    return false;
  }

  const isNotConnected = currentUser.medicalConnectionStatus !== MedicalConnectionStatus.CONNECTED;

  if (isNotConnected) {
    return false;
  }

  const isOldConnection = isOlderConnection(currentUser);

  if (isOldConnection === null) {
    return false;
  }

  return isOldConnection === false;
});
</script>

<style scoped></style>
