<template>
  <div>
    <label
      :for="inputId"
      :class="{ required: isRequired }"
      class="mb-2 block text-sm font-medium"
      >{{ label }}</label
    >
    <div class="relative">
      <input
        :id="inputId"
        :name="name"
        :type="inputType"
        :aria-describedby="helperTextId"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :value="modelValue"
        :disabled="isDisabled"
        class="focus:bg-color-white hover:bg-color-white block w-full rounded-lg border-brick-700 bg-white p-2.5 text-base text-gray-900 placeholder-brick-700 placeholder:font-medium hover:border-yellow-600 focus:border-yellow-600 focus:ring-0 focus:ring-offset-0"
        :class="{
          'border-red-500 bg-red-50 text-red-700': hasInputError,
          'border-brick-700 opacity-50': isDisabled,
        }"
        data-matomo-mask
        @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        @focus="handleSetFocus(true)"
        @focusout="handleSetFocus(false)"
      />
      <button
        v-show="hasInputValue"
        type="button"
        class="absolute inset-y-0 right-2 flex items-center px-2 py-1.5"
        :aria-label="ariaLabelInput"
        @focus="handleSetFocus(true)"
        @focusout="handleSetFocus(false)"
        @click="handleIconClick()"
      >
        <SvgIcon v-if="showPassword" name="eyeSlash" :type="svgType" class="text-lg" />
        <SvgIcon v-show="!showPassword" :name="iconName" :type="svgType" class="text-lg" />
      </button>
    </div>

    <p
      v-if="!hasInputError && !isRegistrationPassword"
      :id="helperTextId"
      class="text-sm font-light text-gray-700"
    >
      <slot />
    </p>

    <InputFieldValidationHelperText
      v-if="hasInputError && !isRegistrationPassword"
      :input-id="inputId"
      :helper-text-id="helperTextId"
    />

    <InputFieldPasswordValidationHelperText
      v-if="isRegistrationPassword"
      :helper-text-id="helperTextId"
      :is-input-focused="isInputFocused"
    />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed, ref } from 'vue';

import InputFieldPasswordValidationHelperText from '@/components/InputFieldPasswordValidationHelperText.vue';
import InputFieldValidationHelperText from '@/components/InputFieldValidationHelperText.vue';
import SvgIcon from '@/components/SvgIcon.vue';

const props = defineProps<{
  modelValue: string | undefined;
  type: 'text' | 'password' | 'tel' | 'email' | 'number';
  inputId: string;
  isRequired: boolean;
  name?: string;
  hasCustomValidation?: boolean;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  autocomplete?: string;
}>();
const emit = defineEmits(['update:modelValue']);
const helperTextId = `${props.inputId}HelperText`;
const showPassword = ref(false);
const inputType = ref(props.type);
const iconName = inputType.value === 'password' ? 'eye' : 'xMark';

const ariaLabelInput = ref(inputType.value === 'password' ? 'Passwort anzeigen' : 'Text löschen');

const { errorMessage } = useField(props.inputId);

const isInputFocused = ref(false);

const hasInputValue = computed(() => {
  return !!props.modelValue;
});

const hasInputError = computed(() => {
  return !!errorMessage.value;
});

const svgType = computed(() => {
  return hasInputError.value ? 'error' : 'default';
});

const isRegistrationPassword = computed(() => {
  return props.type === 'password' && props.hasCustomValidation;
});

function toggleShowPassword(): void {
  showPassword.value = !showPassword.value;
  inputType.value = showPassword.value ? 'text' : 'password';
  ariaLabelInput.value = showPassword.value ? 'Passwort verstecken' : 'Passwort anzeigen';
}

function clearValue(): void {
  emit('update:modelValue', '');
}

function handleIconClick(): void {
  if (props.type === 'password') {
    toggleShowPassword();

    return;
  }

  clearValue();
}

function handleSetFocus(focusState: boolean): void {
  isInputFocused.value = focusState;
}
</script>

<style scoped>
p:empty::before {
  display: inline-block;
  content: '';
}

.required:after {
  content: ' *';
}
</style>
