const DELIMITER = '.';

export function formatBirthdayInput(date: string): string {
  let updatedDate = date.replace(/[^0-9]/g, '');
  let resultDate = '';

  if (updatedDate.length > 2) {
    resultDate += updatedDate.substring(0, 2) + DELIMITER;
    updatedDate = updatedDate.substring(2);
  }
  if (updatedDate.length > 2) {
    resultDate += updatedDate.substring(0, 2) + DELIMITER;
    updatedDate = updatedDate.substring(2);
  }

  resultDate += updatedDate;

  return resultDate;
}
