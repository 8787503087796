import type { NavigationItem } from '@/types/NavigationItem';

export const navigationItems: NavigationItem[] = [
  {
    name: 'Start',
    icon: 'home',
    route: 'home',
  },
  {
    name: 'Dokumente',
    icon: 'documents',
    route: 'documents',
  },
  {
    name: 'Termine',
    icon: 'appointments',
    route: 'appointments',
  },
  {
    name: 'Nachrichten',
    icon: 'messages',
    route: 'messages',
    classes: 'md:hidden',
  },
];
