import axios from 'axios';

import { AppointmentService } from '@/services/appointment.service';
import { addAuthInterceptor } from '@/services/auth.interceptor';
import { AuthService } from '@/services/auth.service';
import { FileService } from '@/services/file.service';
import { InvoiceService } from '@/services/invoice.service';
import { Logger } from '@/services/logger';
import { UserService } from '@/services/profile.service';
import { UserPreferenceService } from '@/services/userPreference.service';
import { VerificationTokenService } from '@/services/verificationToken.service';

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
const environmentMode = import.meta.env.MODE;

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  withXSRFToken: true,
});

addAuthInterceptor(axiosInstance);
export const appointmentService = new AppointmentService(axiosInstance);
export const authService = new AuthService(axiosInstance);
export const fileService = new FileService(axiosInstance);
export const invoiceService = new InvoiceService(axiosInstance);
export const logger = new Logger(environmentMode);
export const userService = new UserService(axiosInstance);
export const userPreferenceService = new UserPreferenceService(axiosInstance);

export const verificationTokenService = new VerificationTokenService(axiosInstance);
