import { BaseService } from '@/services/base.service';
import type { Appointment } from '@/types/api/Appointment.model';
import type { AppointmentStatus } from '@/types/api/AppointmentStatus.model';
import type { Page } from '@/types/api/Page.model';
import type { Resource } from '@/types/api/Resource.model';
import type { HttpClientResponse } from '@/types/HttpClientResponse';

// To disable pagination for now we are setting a high number for items per page
const FILE_REQUEST_LIMIT = 15;

export class AppointmentService extends BaseService {
  async getAppointments(
    status: AppointmentStatus | AppointmentStatus[],
    direction: 'asc' | 'desc' = 'asc',
    pageRequestLink?: string,
  ): Promise<HttpClientResponse<Page<Appointment>>> {
    const statusArray = Array.isArray(status) ? status : [status];

    const requestLink = pageRequestLink ? pageRequestLink : '/api/appointments/search';

    return this.post<Page<Appointment>>(requestLink, {
      scopes: [
        {
          name: 'status',
          parameters: statusArray,
        },
      ],
      sort: [
        {
          field: 'started_at',
          direction,
        },
      ],
      limit: FILE_REQUEST_LIMIT,
    });
  }

  async getAppointment(id: string): Promise<HttpClientResponse<Resource<Appointment>>> {
    return this.get<Resource<Appointment>>(`/api/appointments/${id}`);
  }
}
