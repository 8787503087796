import { MembershipStatus } from '@/types/api/MembershipStatus.enum';

export default function getHigherMemberships(
  userMembership?: MembershipStatus,
): MembershipStatus[] {
  switch (userMembership) {
    case MembershipStatus.NON_MEMBERS:
      return [MembershipStatus.VORSORGE, MembershipStatus.RUNDUM, MembershipStatus.RUNDUM_PLUS];
    case MembershipStatus.VORSORGE:
      return [MembershipStatus.RUNDUM, MembershipStatus.RUNDUM_PLUS];
    case MembershipStatus.RUNDUM:
      return [MembershipStatus.RUNDUM_PLUS];
    case MembershipStatus.RUNDUM_PLUS:
    default:
      return [];
  }
}
