import { BaseService } from '@/services/base.service';
import type { Resource } from '@/types/api/Resource.model';
import type { User } from '@/types/api/User.model';
import type { HttpClientResponse } from '@/types/HttpClientResponse';

export class UserService extends BaseService {
  async update(
    userId: string,
    firstName: string,
    lastName: string,
    birthdate: string,
    socialSecurityNumber: string,
  ): Promise<HttpClientResponse<Resource<User>>> {
    await this.get('/sanctum/csrf-cookie');

    const strippedSocialSecurityNumber = socialSecurityNumber.replace(' ', '');

    return this.patch<Resource<User>>(`/api/users/${userId}`, {
      firstName,
      lastName,
      birthdate,
      socialSecurityNumber: strippedSocialSecurityNumber,
    });
  }
}
