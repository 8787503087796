import type { RouteLocationRaw } from 'vue-router';

import { useUserStore } from '@/stores/user.store';
import { MembershipStatus } from '@/types/api/MembershipStatus.enum';

export default async function membershipGuard(): Promise<Partial<RouteLocationRaw> | void> {
  const { user: storedUser } = useUserStore();

  if (storedUser?.membership === MembershipStatus.NON_MEMBERS) {
    return { name: 'home' };
  }
}
