import { BaseService } from '@/services/base.service';
import type { File } from '@/types/api/File.model';
import type { FileType } from '@/types/api/FileType.enum';
import type { Page } from '@/types/api/Page.model';
import type { HttpClientResponse } from '@/types/HttpClientResponse';

// To disable pagination for now we are setting a high number for items per page
const FILE_REQUEST_LIMIT = 1000;

export class FileService extends BaseService {
  async getFiles(
    type: FileType | null,
    pageRequestLink?: string,
    pageSize: number = FILE_REQUEST_LIMIT,
  ): Promise<HttpClientResponse<Page<File>>> {
    const requestLink = pageRequestLink ? pageRequestLink : '/api/files/search';

    return this.post<Page<File>>(requestLink, {
      filters: [
        {
          field: 'type',
          operator: '=',
          value: type,
        },
      ],
      sort: [
        {
          field: 'date',
          direction: 'desc',
        },
      ],
      limit: pageSize,
    });
  }
}
