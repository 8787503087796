<template>
  <component :is="layoutComponent">
    <RouterView />
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const layoutComponent = computed(() => {
  const layout = route?.meta?.layout;

  if (layout !== undefined) {
    return `The${layout}Layout`;
  }

  return 'div';
});
</script>
