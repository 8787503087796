import { captureMessage } from '@sentry/vue';

export class Logger {
  private readonly environment: 'production' | 'staging' | 'development';

  constructor(private mode: string) {
    if (mode === 'production') {
      this.environment = 'production';
    } else if (mode === 'staging') {
      this.environment = 'staging';
    } else {
      this.environment = 'development';
    }
  }

  public info(message: string): void {
    if (this.environment !== 'production') {
      console.log(message);
    }
  }

  public error(message: string): void {
    if (this.environment === 'production') {
      captureMessage(message);

      return;
    }

    throw Error(message);
  }
}
