import { BaseService } from '@/services/base.service';
import type { EmptyResponse } from '@/types/api/EmptyResponse';
import type { Resource } from '@/types/api/Resource.model';
import type { UserPreference } from '@/types/api/UserPreference.model';
import type { UserPreferencesType } from '@/types/api/UserPreferencesType.enum';
import type { HttpClientResponse } from '@/types/HttpClientResponse';

export class UserPreferenceService extends BaseService {
  async getAll(): Promise<HttpClientResponse<Resource<UserPreference[]>>> {
    return this.get<Resource<UserPreference[]>>('/api/user-preference');
  }

  /**
   * Only use this method through the user-preferences store.
   * This will also update the store and all linked preferences
   */
  async enable(
    userPreferencesType: UserPreferencesType,
  ): Promise<HttpClientResponse<EmptyResponse>> {
    await this.get('/sanctum/csrf-cookie');

    return this.post<EmptyResponse>('api/user-preference/enable', {
      type: userPreferencesType,
    });
  }

  /**
   * Only use this method through the user-preferences store.
   * This will also update the store and all linked preferences
   */
  async disable(
    userPreferencesType: UserPreferencesType,
  ): Promise<HttpClientResponse<EmptyResponse>> {
    await this.get('/sanctum/csrf-cookie');

    return this.post<EmptyResponse>('api/user-preference/disable', {
      type: userPreferencesType,
    });
  }
}
