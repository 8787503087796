import { HttpStatusCode } from 'axios';
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { verificationTokenService } from '@/services';
import { useUnauthenticatedStore } from '@/stores/unauthenticated.store';
import { LinkExpiredType } from '@/types/LinkExpiredType.enum';
import { isString } from '@/utils/assertString';

export default async function verificationTokenGuard(
  to: RouteLocationNormalized,
): Promise<Partial<RouteLocationRaw> | void> {
  const { verificationCode } = to.query;
  const store = useUnauthenticatedStore();

  if (!isString(verificationCode)) {
    return;
  }

  const { statusCode, data } = await verificationTokenService.validate(verificationCode);

  if (statusCode !== HttpStatusCode.Ok) {
    return { name: 'link-expired', query: { linkExpiredType: LinkExpiredType.REGISTRATION } };
  } else if (data?.email) {
    store.$patch({
      unauthenticated: { email: data.email },
    });

    return { path: to.fullPath, query: { verified: 'true' } };
  }
}
