import type { AxiosInstance } from 'axios';
import { isAxiosError } from 'axios';

import type { HttpClientResponse } from '@/types/HttpClientResponse';

type AxiosTypes = 'get' | 'post' | 'patch';

export class BaseService {
  constructor(protected httpClient: AxiosInstance) {}

  protected async get<T = unknown>(path: string): Promise<HttpClientResponse<T>> {
    return this.requestAndTransform<T>('get', path);
  }

  protected async patch<T = unknown>(path: string, postData?: any): Promise<HttpClientResponse<T>> {
    return this.requestAndTransform<T>('patch', path, postData);
  }

  protected async post<T = unknown>(path: string, postData?: any): Promise<HttpClientResponse<T>> {
    return this.requestAndTransform<T>('post', path, postData);
  }

  private async requestAndTransform<T = unknown>(
    requestType: AxiosTypes,
    path: string,
    postData?: any,
  ): Promise<HttpClientResponse<T>> {
    try {
      const { data, status } = await this.httpClient[requestType]<T>(path, postData);

      return { error: undefined, data, statusCode: status };
    } catch (error: any) {
      let transformedError: Error = error;
      if (isAxiosError<Error>(error)) {
        transformedError = new Error(error.response?.data.message);
      }

      return { error: transformedError, data: undefined, statusCode: error.response?.status };
    }
  }
}
