<template>
  <a
    v-if="isExternal && typeof to === 'string'"
    v-bind="$attrs"
    :href="to"
    target="_blank"
    :class="[variantClasses, { underline: props.underline }]"
  >
    <slot />
  </a>
  <RouterLink v-else v-bind="$props" :class="[variantClasses]">
    <slot />
  </RouterLink>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RouterLink } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';

import { isExternalLink } from '@/utils/isExternalLink';

const props = withDefaults(
  defineProps<{
    to: RouteLocationRaw;
    variant?: 'link' | 'link-danger' | 'plain' | 'small';
    underline?: boolean;
  }>(),
  {
    variant: 'link',
    underline: true,
  },
);

const variantClasses = computed(() => {
  switch (props.variant) {
    case 'link':
      return 'visited:text-gray-900 hover:text-yellow-700 font-medium disabled:opacity-50';
    case 'link-danger':
      return 'text-red-600 visited:text-gray-900 hover:text-yellow-700 font-medium disabled:opacity-50';
    case 'small':
      return 'text-sm font-normal';
    case 'plain':
    default:
      return '';
  }
});

const isExternal = computed(() => isExternalLink(props.to));
</script>

<style scoped></style>
