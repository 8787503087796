import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { logger, userPreferenceService } from '@/services';
import type { UserPreference } from '@/types/api/UserPreference.model';
import { UserPreferencesType } from '@/types/api/UserPreferencesType.enum';

function shouldShowBox(userPreferences: UserPreference[], type: UserPreferencesType): boolean {
  return !userPreferences.find(
    (userPreference: UserPreference) =>
      userPreference.type === type && userPreference.value === true,
  );
}

export const useUserPreferencesStore = defineStore('user-preferences', () => {
  const pending = ref(false);
  const userPreferences = ref<UserPreference[] | undefined | null>(undefined);

  const shouldShowMedicalBox = computed(() => {
    const currentUserPreferences = userPreferences.value;

    // to not display it while loading the user preferences
    if (currentUserPreferences === undefined) {
      return false;
    }

    if (currentUserPreferences === null) {
      return true;
    }

    return shouldShowBox(currentUserPreferences, UserPreferencesType.HIDE_COMPLETION_SUCCESS_BOX);
  });

  const shouldShowMembershipUpsellBox = computed(() => {
    const currentUserPreferences = userPreferences.value;

    // to not display it while loading the user preferences
    if (currentUserPreferences === undefined) {
      return false;
    }

    if (currentUserPreferences === null) {
      return true;
    }

    return shouldShowBox(currentUserPreferences, UserPreferencesType.HIDE_MEMBERSHIP_UPSELL_BOX);
  });

  async function fetchPreferences(useCache: boolean = true): Promise<void> {
    if ((useCache && userPreferences.value !== undefined) || pending.value === true) {
      return;
    }

    pending.value = true;
    const fetchedUserPreferencesResponse = await userPreferenceService.getAll();

    if (!fetchedUserPreferencesResponse || fetchedUserPreferencesResponse.error) {
      logger.error(
        `Error while fetching user preferences. Error: ${fetchedUserPreferencesResponse.error.message}`,
      );
      userPreferences.value = null;
      pending.value = false;

      return;
    }

    userPreferences.value = fetchedUserPreferencesResponse.data.data;
    pending.value = false;
  }

  async function closeMedicalInformationBox(): Promise<void> {
    // use optimistic UI to hide it immediately and not wait for response
    const currentUserPreferences = userPreferences.value ?? [];
    userPreferences.value = [
      ...currentUserPreferences,
      {
        type: UserPreferencesType.HIDE_COMPLETION_SUCCESS_BOX,
        value: true,
      },
    ];
    await userPreferenceService.enable(UserPreferencesType.HIDE_COMPLETION_SUCCESS_BOX);
    await fetchPreferences(false);
  }

  async function closeMembershipUpsellBox(): Promise<void> {
    // use optimistic UI to hide it immediately and not wait for response
    const currentUserPreferences = userPreferences.value ?? [];
    userPreferences.value = [
      ...currentUserPreferences,
      {
        type: UserPreferencesType.HIDE_MEMBERSHIP_UPSELL_BOX,
        value: true,
      },
    ];
    await userPreferenceService.enable(UserPreferencesType.HIDE_MEMBERSHIP_UPSELL_BOX);
    await fetchPreferences(false);
  }

  function $reset(): void {
    pending.value = false;
    userPreferences.value = null;
  }

  return {
    userPreferences,
    shouldShowMedicalBox,
    closeMedicalInformationBox,
    shouldShowMembershipUpsellBox,
    closeMembershipUpsellBox,
    fetchPreferences,
    $reset,
  };
});
