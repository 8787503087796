import type { MedicalConnectionStatus } from '@/types/api/MedicalConnectionStatus.enum';
import type { MembershipStatus } from '@/types/api/MembershipStatus.enum';
import type { PatientFinderResult } from '@/types/api/PatientFinderResult.enum';
import { getLastWeeksDate } from '@/utils/getLastWeeksDate';
const LOCALE = import.meta.env.VITE_LOCALE;

export interface User {
  id: string;
  email: string;
  avatarId: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  fullNameWithTitle: string | null;
  birthdate: string | null;
  socialSecurityNumber: string | null;
  gender: string | null;
  membership: MembershipStatus;
  companyName: string | null;
  hasMedicalConnection: boolean;
  medicalConnectionStatus: MedicalConnectionStatus;
  hasCompletedProfile: boolean;
  maskedMedicalEmailAddress?: string;
  lastMedicalConnectionResult?: PatientFinderResult;
  lastMedicalConnectionResultAt?: string;
}

export function isOlderConnection(user: User): boolean | null {
  if (user.lastMedicalConnectionResultAt === undefined) {
    return null;
  }

  const lastMedicalConnectionDate = new Date(user.lastMedicalConnectionResultAt);

  return lastMedicalConnectionDate < getLastWeeksDate();
}

export function transformBirthdate(birthdate: Date): string {
  return birthdate.toLocaleDateString(LOCALE, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
