<template>
  <nav class="shrink-0 bg-white">
    <ul class="flex flex-wrap justify-evenly md:flex-col">
      <template v-for="item in props.items" :key="item.route">
        <RouterLink
          :to="{ name: item.route }"
          class="flex-1 grow text-gray-800 hover:text-gray-900 md:my-2"
          :active-class="props.activeClass"
          :class="item.classes"
          @click="handleClick(item.route)"
        >
          <li
            class="flex shrink-0 flex-col items-center justify-center p-2 sm:px-4 md:flex-row md:justify-start md:py-3"
          >
            <template v-if="item.icon">
              <SvgIcon :name="item.icon" class="text-2xl" aria-hidden="true" focusable="false" />
            </template>
            <span :class="{ 'md:ml-4': item.icon }">{{ item.name }}</span>
          </li>
        </RouterLink>
      </template>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/SvgIcon.vue';
import { maximizeChat } from '@/plugins/userlike';
import type { NavigationItem } from '@/types/NavigationItem';

const props = defineProps<{
  items: NavigationItem[];
  activeClass: string;
}>();

async function handleClick(itemRoute: string): Promise<void> {
  if (itemRoute === 'messages') {
    await maximizeChat();
  }
}
</script>
