<template>
  <div class="flex flex-col rounded-lg bg-gray-50 p-12 md:flex-row">
    <div class="flex flex-col justify-center md:w-2/3 md:pr-4">
      <h2>Geschafft!</h2>
      <p class="mt-2">
        Wir haben dir einen Bestätigungslink
        <span v-if="user && user.maskedMedicalEmailAddress"
          >an {{ user.maskedMedicalEmailAddress }}</span
        >
        gesendet.
      </p>
      <p class="mt-2">Bitte klicke den Link an, um die Aktualisierung abzuschließen.</p>
    </div>
    <EmailIllustration aria-hidden="true" class="mt-4 flex self-center text-sm md:mt-0 md:w-1/3" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import EmailIllustration from '@/assets/svg/emailIllustration.svg';
import { useUserStore } from '@/stores/user.store';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
</script>

<style scoped></style>
