import type { AxiosInstance, AxiosResponse } from 'axios';
import { HttpStatusCode } from 'axios';

import { useCustomToast } from '@/composables/useCustomToast';
import { externalPages } from '@/constants/routesExternalPages';
import router from '@/router/router';
import { authService } from '@/services/index';
import { useUserStore } from '@/stores/user.store';

const { showErrorToast } = useCustomToast();
const location = window.location.pathname.slice(1);
const isOnInternalPage = !externalPages.includes(location);

export function addAuthInterceptor(authClient: AxiosInstance): void {
  authClient.interceptors.response.use(
    (response: AxiosResponse<any, any>) => {
      return response;
    },
    async (error: any) => {
      if (
        error.response &&
        [HttpStatusCode.Unauthorized].includes(error.response.status && isOnInternalPage)
      ) {
        const store = useUserStore();

        await authService.logout();
        await router.push({ name: 'login' });

        if (store.wasUserLoggedOut) {
          showErrorToast({
            title: 'Deine Sitzung ist abgelaufen.',
            text: 'Du wurdest ausgeloggt, um dein Konto zu schützen. Logge dich erneut ein, um fortzufahren.',
          });
        }
      }

      const rejectionError = error instanceof Error ? error : new Error(error);

      return Promise.reject(rejectionError);
    },
  );
}
