import { createMessenger, v1 } from '@userlike/messenger';
import type { ActionResult } from '@userlike/messenger';

import { useUserStore } from '@/stores/user.store';
import { MembershipStatus } from '@/types/api/MembershipStatus.enum';
import type { User } from '@/types/api/User.model';

const userLikeNotMemberWidgetKey = import.meta.env.VITE_USERLIKE_NOT_MEMBER_KEY;
const userLikeMemberWidgetKey = import.meta.env.VITE_USERLIKE_MEMBER_KEY;
const userLikeBMSMemberWidgetKey = import.meta.env.VITE_USERLIKE_BMS_MEMBER_KEY;
const isNotMobile = window.matchMedia('(min-width: 768px)');

function isBmsEmail(email: string): boolean {
  return email.endsWith('@bms.com');
}

function getWidgetKey(membership: string, email: string): string {
  if (isBmsEmail(email)) {
    return userLikeBMSMemberWidgetKey;
  }

  if (membership === MembershipStatus.NON_MEMBERS) {
    return userLikeNotMemberWidgetKey;
  }

  return userLikeMemberWidgetKey;
}

async function createApiForMembership(membership: string, email: string): Promise<v1.Api> {
  const widgetKey = getWidgetKey(membership, email);

  const result = await createMessenger({
    version: 1,
    widgetKey,
  });

  if (result.kind === 'error') {
    throw new Error(result.error);
  }

  const { api } = result.value;

  if (api === null) {
    throw new Error('api reached end-of-life, please check documentation and upgrade.');
  }

  return api;
}

function setUserlikeContactInfo(api: v1.Api, user: User): Promise<ActionResult<string, void>> {
  return api.setContactInfo({
    name: user.fullName,
    email: user.email,
  });
}

async function setupUserlikeForStoredUser(storedUser: User): Promise<v1.Api | undefined> {
  const api = await createApiForMembership(storedUser.membership, storedUser.email);

  await api.mount();
  await setUserlikeContactInfo(api, storedUser);

  return api;
}

async function hideChatButton(userLikeApi: v1.Api): Promise<void> {
  await userLikeApi.setVisibility({
    main: true,
    button: false,
    notifications: false,
  });
}

async function showChatButton(userLikeApi: v1.Api): Promise<void> {
  await userLikeApi.setVisibility({
    main: true,
    button: true,
    notifications: true,
  });
}

async function showChatBasedOnScreenWidth(
  userLikeApi: undefined | v1.Api,
  event?: MediaQueryListEvent,
  mediaQuery?: MediaQueryList,
): Promise<void> {
  if (!userLikeApi) {
    return;
  }

  if (event?.matches || mediaQuery?.matches) {
    await showChatButton(userLikeApi);
  } else {
    await hideChatButton(userLikeApi);
  }
}

async function mount(storedUser: User): Promise<void> {
  const userLikeApi = await setupUserlikeForStoredUser(storedUser);
  await showChatBasedOnScreenWidth(userLikeApi, undefined, isNotMobile);
  window.haelsiUserlike = userLikeApi;
}

async function unmount(): Promise<void> {
  if (window.haelsiUserlike === undefined) {
    return;
  }

  await window.haelsiUserlike.unmount();
  window.haelsiUserlike = undefined;
}

export async function useUserlike(): Promise<void> {
  const userStore = useUserStore();

  userStore.$subscribe(async (mutation, state) => {
    const api = window.haelsiUserlike;

    if (api !== undefined && state.user !== null) {
      return setUserlikeContactInfo(api, state.user);
    }

    if (state.user !== null) {
      return mount(state.user);
    }

    return unmount();
  });

  isNotMobile.addEventListener('change', async (event: MediaQueryListEvent) => {
    await showChatBasedOnScreenWidth(window.haelsiUserlike, event);
  });
}
