export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function assertString(value: unknown): asserts value is string {
  if (isString(value)) {
    return;
  }

  throw new Error('Not a string');
}
