<template>
  <button
    id="dropdownMenuIconButton"
    ref="dropdownTriggerElement"
    data-dropdown-offset-skidding="-90"
    class="rounded-full bg-brick-700 p-1.5 text-2xl text-white hover:bg-yellow hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-yellow active:bg-gray-900 active:text-white md:ml-4 md:p-2.5"
    data-dropdown-toggle="dropdownMenu"
    type="button"
    aria-label="Dropdown-Menü öffnen"
    name="Benutzerprofil-Icon"
  >
    <SvgIcon name="profile" />
  </button>

  <div
    id="dropdownMenu"
    ref="dropdownTargetElement"
    class="z-40 hidden w-full divide-y divide-gray-100 rounded-lg bg-white shadow-lg md:w-56"
  >
    <ul class="py-2 text-sm" aria-labelledby="dropdownMenuIconButton" @click="handleDropdownClick">
      <li>
        <AppLink
          name="Dein-Profil"
          variant="small"
          :to="{ name: 'membership' }"
          class="block p-3 hover:bg-gray-100"
        >
          Dein Profil
        </AppLink>
      </li>
      <li>
        <AppLink variant="small" :to="{ name: 'invoices' }" class="block p-3 hover:bg-gray-100">
          Rechnungen
        </AppLink>
      </li>
      <li>
        <AppLink
          to="#"
          variant="small"
          class="block border-t-2 border-t-brick-100 p-3 hover:bg-gray-100"
          @click.prevent="handleLogout"
        >
          Ausloggen
        </AppLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { Dropdown } from 'flowbite';
import { onMounted, ref } from 'vue';

import AppLink from '@/components/AppLink.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import router from '@/router/router';
import { authService } from '@/services';

const dropdownTargetElement = ref<HTMLElement | null>(null);
const dropdownTriggerElement = ref<HTMLElement | null>(null);
const dropdown = ref<Dropdown | null>(null);

onMounted(() => {
  dropdown.value = new Dropdown(dropdownTargetElement.value, dropdownTriggerElement.value);
});

function handleLogout(): void {
  authService.logout().then(() => {
    /**
     * Always redirect to the login even if there is an error
     *
     * We remove the user storage information when logging out. This is the
     * location which we check if the user is authenticated. So the user shows
     * as not authenticated in our application which is fine for now.
     */
    router.push({ name: 'login' });
  });
}

function handleDropdownClick(): void {
  dropdown.value?.hide();
}
</script>

<style scoped></style>
