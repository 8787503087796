<template>
  <AppButton variant="icon" name="Zurueck" @click="handleClick">
    <SvgIcon name="arrowLeft" class="text-gray-900" aria-label="Zurück" />
  </AppButton>
</template>

<script setup lang="ts">
import AppButton from '@/components/AppButton.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import router from '@/router/router';

const handleClick = (): void => {
  router.back();
};
</script>
