<template>
  <div class="h-10">
    <p
      v-if="computedProps.hasPasswordRequiredError"
      :id="helperTextId"
      class="error text-sm font-light text-gray-700"
    >
      {{ PasswordErrors.required }}
    </p>

    <ul
      v-if="computedProps.showCustomPasswordValidation"
      :id="helperTextId"
      class="grid grid-cols-2 text-sm font-light text-gray-700"
    >
      <li
        v-for="error in customErrorsArray"
        :key="error + 'Key'"
        class="flex"
        :class="{ error: errors.includes(error), valid: !errors.includes(error) && !!value }"
      >
        <SvgIcon class="mr-2 mt-1" name="checkbox" aria-hidden="true" /> {{ error }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed } from 'vue';

import SvgIcon from '@/components/SvgIcon.vue';
import { PasswordErrors } from '@/constants/errors/registration.error';

const props = defineProps<{
  helperTextId?: string;
  isInputFocused: boolean;
}>();

const { errors, value } = useField('password');

const allPasswordErrors: string[] = Object.values(PasswordErrors);
const customErrorsArray: string[] = allPasswordErrors.filter(
  (error) => error !== PasswordErrors.required,
);

const computedProps = computed(() => {
  const hasPasswordRequiredError = errors.value.includes(PasswordErrors.required);
  const showCustomPasswordValidation =
    !hasPasswordRequiredError && (props.isInputFocused || errors.value.length !== 0);

  return {
    hasPasswordRequiredError,
    showCustomPasswordValidation,
  };
});
</script>

<style scoped>
.error {
  color: theme('colors.red.700');
}

.valid {
  color: theme('colors.green.700');
}
</style>
