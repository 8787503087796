<template>
  <TheAppLayout />
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue';

import { useUserlike } from '@/composables/userlike';
import { useUserStore } from '@/stores/user.store';

useUserlike();

const store = useUserStore();
watch(
  () => store.user,
  (newUser, user) => {
    if (user && newUser === null) {
      store.$patch({ wasUserLoggedOut: true });
    }
  },
);

onMounted(() => {
  window.addEventListener('vite:preloadError', (event) => {
    event.preventDefault(); // to not throw the error
    window.location.reload();
  });
});
</script>
