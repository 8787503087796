export enum MembershipStatus {
  NON_MEMBERS = 'NON_MEMBERS',
  VORSORGE = 'VORSORGE',
  RUNDUM = 'RUNDUM',
  RUNDUM_PLUS = 'RUNDUM_PLUS',
  BASIC = 'BASIC',
  BASIC_KIDS = 'BASIC_KIDS',
  PLUS = 'PLUS',
  PLUS_KIDS = 'PLUS_KIDS',
}

export const membershipStatusNames: Record<MembershipStatus, string> = {
  [MembershipStatus.NON_MEMBERS]: 'Kostenlos',
  [MembershipStatus.VORSORGE]: 'Vorsorge',
  [MembershipStatus.RUNDUM]: 'Rundum',
  [MembershipStatus.RUNDUM_PLUS]: 'Rundum Plus',
  [MembershipStatus.BASIC]: 'Basic',
  [MembershipStatus.BASIC_KIDS]: 'Basic Kids',
  [MembershipStatus.PLUS]: 'Plus',
  [MembershipStatus.PLUS_KIDS]: 'Plus Kids',
};
