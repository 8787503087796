export const InputErrors = {
  required: {
    firstname: 'Bitte gib deinen Vornamen ein',
    lastname: 'Bitte gib deinen Nachnamen ein',
    socialSecurityNumber: 'Bitte gib deine Sozialversicherungsnummer ein',
    birthdate: 'Bitte gib dein Geburtsdatum ein',
  },
  incorrect: {
    socialSecurityNumber: 'Bitte gib eine 10 stellige Sozialversicherungsnummer ein',
    birthdate: {
      default: 'Bitte gib ein gültiges Geburtsdatum ein',
      format: 'Das Format muss 01.01.1990 sein',
    },
  },
};
