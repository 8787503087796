import { BaseService } from '@/services/base.service';
import { useUserStore } from '@/stores/user.store';
import type { EmptyResponse } from '@/types/api/EmptyResponse';
import type { LoginResponse } from '@/types/api/LoginResponse.model';
import type { Resource } from '@/types/api/Resource.model';
import type { User } from '@/types/api/User.model';
import type { HttpClientResponse } from '@/types/HttpClientResponse';

interface RegisterResponse {
  requiresEmailVerification: boolean;
}

export class AuthService extends BaseService {
  async login(email: string, password: string): Promise<HttpClientResponse<LoginResponse>> {
    await this.get('/sanctum/csrf-cookie');

    return this.post<LoginResponse>('/api/login', { email, password });
  }

  async forgotPassword(email: string): Promise<HttpClientResponse<EmptyResponse>> {
    await this.get('/sanctum/csrf-cookie');

    return this.post<EmptyResponse>('/api/forgot-password', { email });
  }

  async resetPassword(
    token: string,
    email: string,
    password: string,
  ): Promise<HttpClientResponse<EmptyResponse>> {
    await this.get('/sanctum/csrf-cookie');

    return this.post<EmptyResponse>('/api/reset-password', { token, email, password });
  }

  async register(
    email: string,
    password: string,
    hasAcceptedTermsAndConditions: boolean,
    hasAcceptedAnonymisedDataProcessing: boolean,
    hasAcceptedMedicalResearchConsent: boolean,
    hasAcceptedMarketingConsent: boolean,
  ): Promise<HttpClientResponse<RegisterResponse>> {
    await this.get('/sanctum/csrf-cookie');

    return this.post<RegisterResponse>('/api/register', {
      email,
      password,
      hasAcceptedTermsAndConditions,
      hasAcceptedAnonymisedDataProcessing,
      hasAcceptedMedicalResearchConsent,
      hasAcceptedMarketingConsent,
    });
  }

  logout(): Promise<HttpClientResponse<EmptyResponse>> {
    const { $reset } = useUserStore();
    $reset();

    return this.post<EmptyResponse>('/api/logout');
  }

  // TODO: error handling
  getAuthUser(): Promise<User | undefined> {
    return this.get<Resource<User>>('/api/user').then(({ data: user }) =>
      user ? user.data : undefined,
    );
  }
}
