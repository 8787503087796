export function generateTrackableWebsiteLink(
  path: string,
  mtmCampaign?: string,
  mtmMedium?: string,
): string {
  const base = import.meta.env.VITE_WEB_BASE_URL;

  const url = new URL(path, base);
  if (mtmCampaign) {
    url.searchParams.append('mtm_campaign', mtmCampaign);
    url.searchParams.append('mtm_source', 'portal');

    if (mtmMedium) {
      url.searchParams.append('mtm_medium', mtmMedium);
    }
  }

  return url.toString();
}
