<template>
  <AppLink
    v-if="to"
    :to="to"
    :variant="linkVariant"
    :underline="false"
    :class="[baseClasses, variantClasses]"
  >
    <slot />
  </AppLink>
  <button v-else type="button" :disabled="disabled" :class="[baseClasses, variantClasses]">
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import AppLink from '@/components/AppLink.vue';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    variant?: 'primary' | 'secondary' | 'icon' | 'outline' | 'link';
    to?: RouteLocationRaw;
  }>(),
  {
    disabled: false,
    variant: 'primary',
    to: undefined,
  },
);

const linkVariant = computed(() => {
  return props.variant === 'link' ? 'link' : 'plain';
});

const variantClasses = computed(() => {
  switch (props.variant) {
    case 'primary':
      return 'w-full py-3 px-5 text-sm font-medium bg-yellow text-gray-900 hover:bg-yellow focus:ring-yellow-700 active:ring-yellow-400 hover:bg-yellow-300 gap-2';
    case 'secondary':
      return 'w-full py-3 px-5 text-sm font-medium bg-gray-900 text-white hover:bg-yellow hover:text-gray-900 focus:ring-yellow active:bg-gray-800 active:ring-0';
    case 'outline':
      return 'w-full py-3 px-5 text-sm font-medium border-2 border-gray-900 bg-transparent text-gray-900 ring-0 hover:border-gray-100 hover:bg-gray-100 focus:bg-gray-100 active:border-gray-100 active:bg-gray-700 active:text-white';
    case 'icon':
      return 'w-auto bg-white p-3 hover:bg-gray-100 focus:bg-yellow-200 active:bg-yellow-200 self-center';
    case 'link':
    default:
      return '';
  }
});
const baseClasses =
  'flex items-center justify-center rounded-full focus:outline-none focus:ring-2 disabled:opacity-50 transition duration-300 ease-in-out';
</script>

<style scoped></style>
