import type { Component } from 'vue';
import { TYPE, useToast } from 'vue-toastification';

import CustomToast from '@/components/CustomToast.vue';

interface ToastOptions {
  text: string;
  title?: string;
}

type ShowToastFunction = (props: ToastOptions) => void;

interface UseCustomToast {
  showSuccessToast: ShowToastFunction;
  showErrorToast: ShowToastFunction;
  showInfoToast: ShowToastFunction;
}

interface Toast {
  text: string;
  type: TYPE;
  title?: string;
}

export function useCustomToast(): UseCustomToast {
  const toast = useToast();

  function showToast(props: Toast): void {
    toast(createComponentForToast(props.text, props.type, props.title), {
      icon: false,
      closeButton: false,
    });
  }

  function showSuccessToast(props: ToastOptions): void {
    showToast({ text: props.text, type: TYPE.SUCCESS, title: props.title });
  }

  function showErrorToast(props: ToastOptions): void {
    showToast({ text: props.text, type: TYPE.ERROR, title: props.title });
  }

  function showInfoToast(props: ToastOptions): void {
    showToast({ text: props.text, type: TYPE.INFO, title: props.title });
  }

  return { showSuccessToast, showErrorToast, showInfoToast };
}

function createComponentForToast(text: string, type?: TYPE, title?: string): Component {
  return {
    component: CustomToast,
    props: {
      text,
      type,
      title,
    },
  };
}
