export const PasswordErrors = {
  required: 'Bitte wähle ein sicheres Passwort um dein Konto zu schützen.',
  length: 'Mind. 8 Zeichen',
  uppercase: '1 Großbuchstabe',
  specialSymbol: '1 Sonderzeichen !@ :-)',
  digit: '1 Zahl',
};

export const EmailErrors = {
  email: 'Ups, da stimmt was nicht! Bitte gib eine korrekte E-Mail Adresse ein.',
  required: 'Bitte gib eine gültige E-Mail Adresse ein.',
};

export const ConsentErrors = {
  termsAndConditionsRequired: 'Bitte stimme unseren AGB zu.',
};
