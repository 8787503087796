import { defineStore } from 'pinia';
import { ref } from 'vue';

import { authService } from '@/services';
import type { User } from '@/types/api/User.model';
import { transformBirthdate } from '@/types/api/User.model';

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null);
  const wasUserLoggedOut = ref<boolean>(false);

  async function fetchUser(): Promise<void> {
    const fetchedUser = await authService.getAuthUser();

    if (!fetchedUser) {
      return;
    }

    const birthdate = fetchedUser.birthdate;
    if (birthdate !== null) {
      fetchedUser.birthdate = transformBirthdate(new Date(birthdate));
    }

    user.value = fetchedUser;
  }

  function $reset(): void {
    user.value = null;
  }

  return { user, wasUserLoggedOut, fetchUser, $reset };
});
