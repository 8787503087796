<template>
  <div
    class="flex w-full items-center rounded-lg p-4 text-gray-500"
    :class="{
      'bg-green-50': props.type === TYPE.SUCCESS,
      'bg-red-50': props.type === TYPE.ERROR,
      'bg-orange-50': props.type === TYPE.INFO,
    }"
    role="alert"
  >
    <div
      class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full"
      :class="{
        'bg-green-100 text-green-700': props.type === TYPE.SUCCESS,
        'bg-red-100 text-red-600': props.type === TYPE.ERROR,
        'bg-orange-200 text-orange-700': props.type === TYPE.INFO,
      }"
    >
      <SvgIcon v-if="iconName" :name="iconName" class="text-lg" aria-hidden="true" />
    </div>
    <div class="ml-3 flex flex-col">
      <p v-if="props.title" class="text-base font-medium">{{ props.title }}</p>
      <p class="text-sm font-normal">{{ props.text }}</p>
    </div>
    <button
      type="button"
      class="-mx-1.5 -my-1.5 ml-auto rounded-lg p-1 hover:bg-gray-100 focus:ring-2 focus:ring-gray-300"
      aria-label="Close"
    >
      <span class="sr-only">Close</span>
      <SvgIcon
        name="xMark"
        class="stroke-2 text-xl"
        :class="{
          'text-green-700': props.type === TYPE.SUCCESS,
          'text-red-600': props.type === TYPE.ERROR,
          'text-orange-700': props.type === TYPE.INFO,
        }"
        aria-description="Schließen"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { TYPE } from 'vue-toastification';

import SvgIcon from '@/components/SvgIcon.vue';
import { logger } from '@/services';

const props = defineProps<{
  text: string;
  type: TYPE;
  title?: string;
}>();

const iconName = computed(() => {
  switch (props.type) {
    case TYPE.SUCCESS:
      return 'checkmark';
    case TYPE.ERROR:
      return 'exclamationCircle';
    case TYPE.INFO:
      return 'bell';
    case TYPE.WARNING:
    case TYPE.DEFAULT:
    default:
      logger.error('Toast type is not implemented yet');

      return null;
  }
});
</script>

<style scoped></style>
