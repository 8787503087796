<template>
  <div>
    <label class="mb-2 block text-sm font-medium" :class="{ required: isRequired }" for="birthday">
      {{ label }}
    </label>
    <div
      data-matomo-mask
      class="relative flex w-full items-baseline rounded-lg border border-solid border-brick-700 text-base hover:border-yellow-600 hover:bg-white focus:border-yellow-600 focus:ring-0 focus:ring-offset-0"
    >
      <input
        id="birthday"
        :value="modelValue"
        class="shadow-none w-full appearance-none border-0 bg-transparent p-2.5 text-white caret-gray-900 focus:ring-0"
        type="tel"
        maxlength="10"
        pattern="[0-9]{2}.[0-9]{2}.[0-9]{4}"
        autocomplete="bday"
        @input="handleInput"
      />
      <button
        v-show="hasInputValue"
        type="button"
        class="absolute inset-y-0 right-2 flex items-center px-2 py-1.5"
        aria-label="Text löschen"
        @click="handleClear()"
      >
        <SvgIcon name="xMark" class="text-lg" />
      </button>
      <!-- eslint-disable vue/no-v-html -->
      <!-- we manually restrict to only numbers so we can use v-html here -->
      <span
        class="pointer-events-none absolute left-0 p-2.5 font-medium text-brick-700"
        v-html="placeholderString"
      />
      <!-- eslint-enable -->
    </div>
    <InputFieldValidationHelperText v-if="hasInputError" :input-id="fieldName" />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed, onMounted, ref } from 'vue';

import InputFieldValidationHelperText from '@/components/InputFieldValidationHelperText.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { formatBirthdayInput } from '@/utils/formatBirthdayInput';

const props = defineProps<{
  fieldName: string;
  modelValue: string | undefined;
  isRequired?: boolean;
  label?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const initialPlaceholder = 'TT.MM.JJJJ';
const placeholderString = ref<string>(initialPlaceholder);
const { errorMessage } = useField(props.fieldName);

const hasInputError = computed(() => {
  return !!errorMessage.value;
});
const hasInputValue = computed(() => {
  return !!props.modelValue;
});

onMounted(() => {
  updateValueAndPlaceholder(props.modelValue ?? '');
});

function handleInput(event: Event): void {
  const target = event.target as HTMLInputElement;
  updateValueAndPlaceholder(target.value);
}

function handleClear(): void {
  updateValueAndPlaceholder('');
}

function updateValueAndPlaceholder(value: string): void {
  const formattedValue = formatBirthdayInput(value);

  adaptPlaceholder(formattedValue);
  emit('update:modelValue', formattedValue);
}

function adaptPlaceholder(date: string): void {
  const length = date.length;
  placeholderString.value = `<span class="text-gray-900">${date}</span>${initialPlaceholder.slice(
    length,
  )}`;
}
</script>

<style scoped>
.required:after {
  content: ' *';
}
</style>
