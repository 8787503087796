import { isRef, ref, unref, watchEffect } from 'vue';
import type { Ref } from 'vue';

import type { HttpClientResponse } from '@/types/HttpClientResponse';

interface Fetch<T> {
  loading: Ref<boolean>;
  data: Ref<T | undefined>;
  error: Ref<Error | undefined>;
  statusCode: Ref<number | undefined>;
}

export function useFetch<T>(
  fetchPromise: Promise<HttpClientResponse<T>> | Ref<Promise<HttpClientResponse<T>>>,
): Fetch<T> {
  const loading = ref(true);
  const data = ref<T>();
  const error = ref<Error>();
  const statusCode = ref<number>();

  function updateData(): void {
    loading.value = true;
    data.value = undefined;
    error.value = undefined;
    statusCode.value = undefined;

    unref(fetchPromise).then(
      ({ data: responseData, error: responseError, statusCode: responseStatusCode }) => {
        loading.value = false;
        data.value = responseData;
        error.value = responseError;
        statusCode.value = responseStatusCode;
      },
    );
  }

  if (isRef(fetchPromise)) {
    watchEffect(updateData);
  } else {
    updateData();
  }

  return { loading, data, error, statusCode };
}
