import { BaseService } from '@/services/base.service';
import type { Invoice } from '@/types/api/Invoice.model';
import type { Page } from '@/types/api/Page.model';
import type { Resource } from '@/types/api/Resource.model';
import type { HttpClientResponse } from '@/types/HttpClientResponse';

const REQUEST_LIMIT = 15;

export class InvoiceService extends BaseService {
  async getAll(pageRequestLink?: string): Promise<HttpClientResponse<Page<Invoice>>> {
    const requestLink = pageRequestLink ? pageRequestLink : '/api/invoices/search';

    return this.post<Page<Invoice>>(requestLink, {
      sort: [
        {
          field: 'issued_at',
          direction: 'desc',
        },
      ],
      limit: REQUEST_LIMIT,
    });
  }

  async getOne(id: string): Promise<HttpClientResponse<Resource<Invoice>>> {
    return this.get<Resource<Invoice>>(`/api/invoices/${id}`);
  }

  async getAllCompleted(): Promise<HttpClientResponse<Page<Invoice>>> {
    return this.post<Page<Invoice>>('/api/invoices/search', {
      filters: [
        {
          field: 'payment_status',
          operator: '=',
          value: 'COMPLETED',
        },
      ],
      limit: 1000,
    });
  }
}
