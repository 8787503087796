const DELIMITER = ' ';

export function formatSocialSecurityNumberInput(ssn: string): string {
  const updatedSsn = ssn.replace(/[^0-9]/g, '');
  let resultSsn = updatedSsn;

  if (updatedSsn.length > 4) {
    resultSsn = `${updatedSsn.substring(0, 4)}${DELIMITER}${updatedSsn.substring(4)}`;
  }

  return resultSsn;
}
