import { createGtm } from '@gtm-support/vue-gtm';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import Toast from 'vue-toastification';
import type { PluginOptions } from 'vue-toastification';

import App from '@/App.vue';
import '@/assets/tailwind.css';
import { registerLayouts } from '@/layouts/register';
import { initializeSentry } from '@/plugins/initializeSentry';
const pinia = createPinia();
import router from '@/router/router';
import 'vue-toastification/dist/index.css';
import '@/assets/vue-toastification.css';

const app = createApp(App);

initializeSentry(app, router);

app.use(router);
app.use(pinia);
app.use(
  createGtm({
    id: 'GTM-5VPV3MM',
    defer: false,
    compatibility: false,
    enabled: true,
    debug: import.meta.env.MODE !== 'production',
    loadScript: true,
    vueRouter: router,
    ignoredViews: [],
    trackOnNextTick: false,
  }),
);
registerLayouts(app);

const toastificationOptions: PluginOptions = {
  toastClassName: 'haelsi-toast',
  hideProgressBar: true,
  timeout: 4000,
};
app.use(Toast, toastificationOptions);

app.mount('#app');
