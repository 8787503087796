import { defineStore } from 'pinia';
import { ref } from 'vue';

interface Unauthenticated {
  email?: string;
}

export const useUnauthenticatedStore = defineStore('unauthenticated', () => {
  const unauthenticated = ref<Unauthenticated | null>(null);

  function $reset(): void {
    unauthenticated.value = null;
  }

  return { unauthenticated, $reset };
});
