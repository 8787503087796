import { BaseService } from '@/services/base.service';
import type { HttpClientResponse } from '@/types/HttpClientResponse';

interface Verification {
  email: string | null;
}

export class VerificationTokenService extends BaseService {
  async validate(verificationToken: string): Promise<HttpClientResponse<Verification>> {
    await this.get('/sanctum/csrf-cookie');

    return await this.post<Verification>('/api/validate-verification-code', {
      token: verificationToken,
    });
  }
}
