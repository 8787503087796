import type { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router';

import { assertString } from '@/utils/assertString';

export function addTitleFunctionality(router: Router): void {
  router.beforeEach(
    (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      const title = to.meta.title;

      if (title) {
        assertString(title);
        document.title = `${title} | haelsi`;
      }

      next();
    },
  );
}
