<template>
  <div class="relative flex flex-col rounded-lg bg-gray-50 p-12 md:p-8 lg:flex-row lg:p-12">
    <div class="flex flex-col justify-center lg:w-2/3 lg:pr-4">
      <h2>Dein Konto wurde aktiviert.<br />So geht es weiter!</h2>
      <div class="mt-6 sm:flex">
        <AppButton
          variant="secondary"
          :to="bookAppointmentLink"
          class="lg:!w-1/2 2xl:!w-1/3"
          :class="{ 'sm:mr-2': canUpgradeMembership }"
          >Termin buchen
          <SvgIcon name="chevronLeft" class="ml-1 mt-0.5 rotate-180 text-lg" />
        </AppButton>
        <AppButton
          v-if="canUpgradeMembership"
          variant="outline"
          class="mt-2 sm:ml-2 sm:mt-0 lg:!w-1/2 2xl:!w-1/3"
          name="Mehr-zur-Mitgliedschaft"
          :to="{ name: 'membership' }"
          >Mehr zur Mitgliedschaft
          <SvgIcon name="chevronLeft" class="ml-1 mt-0.5 rotate-180 text-lg" />
        </AppButton>
      </div>
    </div>
    <TadaIllustration
      aria-hidden="true"
      class="mr-18 mt-6 flex w-full self-center pl-20 text-lg md:mt-0 md:w-1/3 md:pl-10 lg:pl-24"
    />
    <CloseButton class="absolute right-4 top-4" @click="handleClose" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import TadaIllustration from '@/assets/svg/tadaIllustration.svg';
import AppButton from '@/components/AppButton.vue';
import CloseButton from '@/components/CloseButton.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { matomoMedium } from '@/constants/matomoMedium';
import { websiteTargetPath } from '@/constants/websiteTargetPath';
import { useUserPreferencesStore } from '@/stores/user-preferences.store';
import { useUserStore } from '@/stores/user.store';
import { generateTrackableWebsiteLink } from '@/utils/generateTrackableWebsiteLink';
import getHigherMemberships from '@/utils/getHigherMemberships';

const bookAppointmentLink = generateTrackableWebsiteLink(
  websiteTargetPath.DEPARTMENTS,
  'Portal Book Appointment',
  matomoMedium.MEDICAL_CONNECTION,
);
const userStore = useUserStore();
const userPreferencesStore = useUserPreferencesStore();

const { user } = storeToRefs(userStore);

function handleClose(): void {
  userPreferencesStore.closeMedicalInformationBox();
}

const canUpgradeMembership =
  getHigherMemberships(user.value?.membership).length > 0 && user.value?.companyName === null;
</script>

<style scoped></style>
